<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <SectionsHeroAlt :hero-alt="heroAlt" />
        <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(item, i) in items" :key="i" :src="getImgUrl(item.src)">
            <!-- <v-sheet height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-h2">{{ slide }}</div>
              </v-row>
            </v-sheet> -->
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      heroAlt: [
        {
          src: "dev-0.jpg",
          heading: " Diseño y Desarrollo de Software ",
        },
      ],
      items: [
        {
          src: "dev-1.jpg",
        },
        {
          src: "dev-2.jpg",
        },
      ],
      slides: [
        "Formando Profesionales",
        "Expertos en Negocios",
        "Expertos en Recursos Humanos",
        "Próximamente",
      ],
    };
  },
  head() {
    return {
      title: "Software",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/" + img);
    },
  },
};
</script>
